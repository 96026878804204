<template>
  <v-dialog v-model="dialog" persistent max-width="70%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{
              labelBtn != "Salvar"
                ? "Editar Descritivo"
                : "Cadastrar Descritivo"
            }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" md="4" lg="4">
              <BaseSelect
                prepend-inner-icon="mdi-file-table-box-multiple-outline"
                clearable
                single-line
                label="Segmento"
                item-text="descricao"
                item-value="id_band"
                service="sistemaService.tipoBandeiras"
                :filters="{ id_band: '2,4' }"
                v-model="form.id_band"
                :isCompany="true"
              />
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <BaseSelect
                label="Função"
                v-model="form.id_funcao"
                service="sistemaService.funcoes"
                item-text="descricao"
                item-value="id_funcao"
                prepend-inner-icon="mdi-account-tie"
                clearable
                :rules="[rules.required]"
                :filters="{ id_band: form.id_band, ativo: 'S' }"
                :hasCode="true"
                :multiple="false"
              />
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <v-autocomplete
                label="Classe"
                v-model="form.id_classe_comissao"
                prepend-inner-icon="mdi-currency-usd"
                chips
                :items="[
                  { text: 'Bônus', value: 'B' },
                  { text: 'Comissão', value: 'C' },
                  { text: 'Salário', value: 'S' }
                ]"
                :rules="[rules.required]"
                :multiple="false"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <v-textarea
                filled
                label="Indicador e Meta"
                :rules="[rules.required]"
                v-model="form.indicador_meta"
              >
              </v-textarea>
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <v-textarea
                filled
                label="Descrição Indicador"
                :rules="[rules.required]"
                v-model="form.descricao_indicador"
              >
              </v-textarea>
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <v-textarea
                filled
                label="Sistema Fonte"
                :rules="[rules.required]"
                v-model="form.sistema_fonte"
              >
              </v-textarea>
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <BaseSelect
                label="Área"
                v-model="form.id_depto"
                service="comissaoService.departamentos"
                item-text="departamento"
                item-value="id_depto"
                prepend-inner-icon="mdi-account-multiple"
                clearable
                :rules="[rules.required]"
                :hasCode="false"
                :multiple="false"
              />
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <v-text-field
                class="pt-5"
                label="Seção"
                :rules="[rules.required]"
                v-model="form.secao"
                prepend-inner-icon="mdi-format-section"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <v-autocomplete
                label="Situação"
                v-model="form.situacao"
                prepend-inner-icon="mdi-list-status"
                chips
                :items="[
                  { text: 'Ativo', value: 'S' },
                  { text: 'Inativo', value: 'N' }
                ]"
                :rules="[rules.required]"
                :multiple="false"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? put() : send()"
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import BaseSelect from "@/components/shared/BaseSelectService";
import comissao from "@/services/http/comissaoService";

export default {
  name: "ModalCadDescritivo",

  mixins: [rules],

  components: {
    BaseSelect
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    labelBtn: {
      type: String
    },
    editDescritivo: {
      type: Object
    }
  },

  data() {
    return {
      form: {}
    };
  },

  watch: {
    editDescritivo(value) {
      this.form = { ...value, situacao: value.situacao.toString() };
    }
  },

  methods: {
    async send() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await comissao()
          .descritivo()
          .store(this.form, {
            notification: true,
            message: "Meta cadastrada com sucesso!"
          });
        this.refresh();
      }
    },

    async put() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await comissao()
          .descritivo(this.form.id)
          .update(this.form, {
            notification: true,
            message: "Meta editada com sucesso!"
          });
        this.refresh();
      }
    },

    close() {
      this.$emit("closeModal");
      this.$refs.form.reset();
    },

    refresh() {
      this.$emit("refresh");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
