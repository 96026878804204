<template>
    <v-container class="container-card">
      <v-card>
        <Descritivo />
      </v-card>
    </v-container>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import Descritivo from "@/components/comissao/descritivo/Descritivo";
  export default {
    components: {
      Descritivo
    },
  
    methods: {
      ...mapActions({
        fetchView: "sistema/fetchView"
      })
    },
  
    mounted() {
      this.fetchView("Descritivo Comissão");
    }
  };
  </script>
  
  <style></style>
  