<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="descritivo"
      :loading="loading"
      :paginate="true"
      :lastPage="nLastPage"
      @pageChange="fetchDescritivo(filters, $event)"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Descritivo Comissão</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom v-can-access="572" @click="openModal()" />
          <FilterDescritivo
            @selectedFilters="fetchDescritivo((filters = $event))"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.id_band`]="{ item }">
        <v-icon :color="bandeiras[item.id_band].color" dark>
          {{ bandeiras[item.id_band].icon }}
        </v-icon>
      </template>

      <template v-slot:[`item.situacao`]="{ item }">
        <v-icon color="success" v-if="item.situacao == 'S'">
          mdi-check
        </v-icon>
        <v-icon color="error" v-else>
          mdi-close
        </v-icon>
      </template>

      <!-- expanded -->

      <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
        <v-btn small text v-if="!isExpanded" @click="expand(!isExpanded)">
          expandir
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn text small v-else @click="expand(!isExpanded)">
          ocultar
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td class="pa-3" :colspan="headers.length">
          <v-row>
            <v-col cols="12" md="6" lg="6">
              <label class="font-weight-bold">Indicador e Meta:</label>
              <v-textarea filled disabled :value="item.indicador_meta">
              </v-textarea>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <label class="font-weight-bold">Descrição Indicador:</label>
              <v-textarea filled disabled :value="item.descricao_indicador">
              </v-textarea>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <label class="font-weight-bold">Sistema Fonte:</label>
              <v-textarea filled disabled :value="item.sistema_fonte">
              </v-textarea>
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <IconBottom
          :name="'edit'"
          v-can-access="186"
          @click="openModalEdit(item)"
        />
        <IconBottom
          :name="'delete'"
          v-can-access="1681"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <FormDescritivo
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editDescritivo="editiItem"
      @closeModal="dialog = false"
      @refresh="refresh()"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable.vue";
import FormDescritivo from "./FormDescritivo.vue";
import comissao from "@/services/http/comissaoService";
import bandeiras from "@/mixins/bandeiras";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import FilterDescritivo from "./FilterDescritivo.vue";
import IconBottom from "@/components/shared/bottons/IconBottom";

export default {
  components: {
    BaseTable,
    FormDescritivo,
    RegisterBottom,
    IconBottom,
    FilterDescritivo
  },

  mixins: [bandeiras],

  data() {
    return {
      loading: false,
      dialog: false,
      singleExpand: false,
      labelBtn: "",
      expanded: [],
      editiItem: {},
      headers: [
        { text: "Segmento", value: "id_band" },
        { text: "Situração", value: "situacao" },
        { text: "Cód. Função", value: "cod_funcao" },
        { text: "Função", value: "funcao_descricao" },
        { text: "Classe", value: "classe_comissao_descricao" },
        { text: "Área", value: "departamento_descricao" },
        { text: "Seção", value: "secao" },
        { text: "Ações", value: "actions" },
        { title: "", key: "data-table-expand" }
      ],
      descritivo: [],
      filters: "",
      nLastPage: 0
    };
  },

  methods: {
    async fetchDescritivo(filter, nPage = 1) {
      let { data } = await comissao()
        .descritivo()
        .show({ page: nPage, ...filter });
      this.nLastPage = data.last_page;
      this.descritivo = data.data;
    },

    openModal() {
      this.dialog = true;
      this.labelBtn = "Salvar";
    },

    openModalEdit(item) {
      this.dialog = true;
      this.labelBtn = "Editar";
      this.editiItem = item;
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          `Deletar Descritivo`,
          `Deseja deletar descritivo para o cargo ${item.funcao_descricao}?`
        );
        comissao()
          .descritivo(item.id)
          .delete(
            {},
            {
              notification: true,
              menssage: "Descritivo deletado com sucesso!"
            }
          );
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar Descritivo"
        });
      } finally {
        this.fetchDescritivo();
      }
    },

    closeModal() {
      this.dialog = false;
      this.$refs.form.reset();
    },

    refresh() {
      this.dialog = false;
      this.fetchDescritivo();
    }
  },

  mounted() {
    this.fetchDescritivo();
  }
};
</script>
